<template>
  <div class="product">
    <!-- 公开课 -->
    <div class="til">
      <div class="title">
        辅导班型
        <img src="@/assets/img/partner/corner.png" alt="" />
      </div>
      <div @click="saveImg('/partnerPage/VIPClass')">
        查看更多
        <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
      </div>
    </div>
    <div class="product_content">

      <div @click="goPage(item)"
        :class="university != 1 ? 'product_content_item' : 'product_content_item product_content_items'"
        v-for="(item, index) in list" :key="index">
        <div class="product_content_item_img">
          <img :src="item.img" alt="" />
        </div>
        <div class="product_content_item_title twoEllipsis">
          {{ item.tiitle }}
        </div>
        <div class="product_content_item_tips">
          <img src="@/assets/img/partner/vip.png" alt="" />
          <span>专属服务 极速答疑 督学跟踪</span>
        </div>
        <div v-if="university != 1" class="product_content_item_price">
          <div class="price">
            <span style="font-size: 16px">￥</span>{{ item.price }}
          </div>
          <el-button class="btn" @click="goPage(item)">去购买</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { selectNewPageListToIndex, selectPageListToIndexnews, getMYTenantKindZyList } from '@/api/home'
import { getInfo } from '@/api/cookies'
import { getDomain } from '@/api/cookies'

export default {
  components: {},
  data() {
    return {
      list: [], // 公开课
      userInfo: null,
      domainInfo: {},
      university: ''
    }
  },
  async created() {
    this.domainInfo = getDomain()
    if (this.domainInfo.eId == process.env.VUE_APP_university) {
      this.university = 1
    }
    this.userInfo = await getInfo()
    this.getList()
  },
  methods: {
    // 项目专业
    getList() {
      this.loading = true
      this.RecommendClassList = []
      selectPageListToIndexnews(
        4,
        1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined, /* productLine 3专属线 */
        undefined
      ).then((res) => {
        this.list = res.rows
        this.total = res.total
      })
    },
    goPage(item) {
      /* this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: '/VIP/VIPClassxq',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.product {
  margin-top: 138px;
  margin-bottom: 15px;
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f5f5;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }

  .til {
    width: 1200px;
    margin: 30px auto 22px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;

    .title {
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: -8px;
        bottom: 0;
      }
    }

    justify-content: space-between;

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .product_content {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;

    .product_content_imgBox {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      width: 586px;
      height: 300px;
      overflow: hidden;
      margin-right: 26px;

      img {
        width: 107%;
        height: 107%;
      }
    }

    .product_content_item {
      width: 280px;
      height: 300px;
      padding: 10px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-right: 26px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }


      .product_content_item_img {
        width: 260px;
        height: 146px;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product_content_item_title {
        width: 260px;
        margin: 10px 0;
        height: 44px;
        line-height: 22px;
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
      }

      .product_content_item_tips {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 11px;

        img {
          width: 65.5px;
          // height: 17px;
          margin-right: 10px;
        }

        .types {
          display: inline-block;
          // width: 24px;
          // height: 17px;
          padding: 1px 8px;
          margin-right: 10px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1c81f5;
        }

        span {
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #cdaa8d;
        }
      }

      .product_content_item_price {
        // width: 244px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price {
          width: 57px;
          height: 25px;
          font-size: 18px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #fb2d25;
          line-height: 25px;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 84px;
          height: 30px;
          background: #ffffff;
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          border: 1px solid #1d81f5;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1d81f5;

          &:hover {
            width: 84px;
            height: 30px;
            background: linear-gradient(180deg, #6cadf8 0%, #1c81f5 100%);
            box-shadow: 0px 3px 12px 1px rgba(28, 129, 245, 0.34);
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            color: #ffffff;
            border: 0;

          }
        }
      }
    }

    .product_content_item {
      height: auto;
    }
  }
}
</style>
