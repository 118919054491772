<template>
  <div>
    <div v-if="teacherList && teacherList.length > 0" class="teacher-view">
      <div class="til">
        <div class="title">
          精品师资
          <img src="@/assets/img/partner/corner.png" alt="" />
        </div>
        <div style="color: #90939a" @click="saveImg('/facultyPartner')">
          查看更多
          <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
        </div>
      </div>
      <div class="tmain">
        <div @mouseenter="move(item)" @mouseleave="uid = ''" class="titem" v-for="(item, index) in teacherList"
          :key="index">
          <div class="timg1"> <img :src="item.terImg" /></div>


          <div v-if="item.id != uid" class="titem_bot">
            <div class="titem_tea elp">
              {{ item.isRecommend == 1 ? item.userName : item.nickname }}
            </div>
            <div v-if="item.jobTitle" class="titem_teaMor">
              {{ item.jobTitle }}
            </div>
          </div>
          <transition name="mouseIn">
            <div class="mouseIn" v-if="item.id == uid">
              <div class="mouseIn_tname">
                <div class="mouseIn_tnames elp">
                  {{ item.isRecommend == 1 ? item.userName : item.nickname }}
                </div>

                <div v-if="item.jobTitle" class="mouseIn_teaMor">
                  {{ item.jobTitle }}
                </div>
              </div>
              <div class="line"></div>
              <div style="margin-top: 30px;" class="tD">讲师简介：</div>
              <div class="tD moreEllipsis" v-html="ImgSizeLimit(item.profile)"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/common.css";
import { TeacherClient } from "@/api/teacherClient";
const teacherClient = new TeacherClient();
export default {
  components: {},
  data() {
    return {
      teacherList: [],
      uid: "",
    };
  },
  async created() {
    this.getlist();
  },
  methods: {
    async getlist() {
      await teacherClient
        .teacherPageList(5, 1, undefined, 1)
        .then(async (res) => {
          if (res.rows) {
            this.teacherList = res.rows;
            this.total = res.total;
          } else {
            this.teacherList = [];
          }
        });
    },
    goDetails() {
      const routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
          id: this.teamsg.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    move(item) {
      this.uid = item.id;
    },
  },
};
</script>

<style lang="less" scoped>
.teacher-view {
  width: 1200px;
  margin: 0px auto 15px;
  // margin-top: 120px;

  .til {
    width: 1200px;
    margin: 0px auto 16px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;
    justify-content: space-between;

    .title {
      position: relative;

      img {
        width: 10px !important;
        height: auto;
        position: absolute !important;
        right: -8px !important;
        bottom: 0 !important;
      }
    }

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .tmain {
    display: flex;
    justify-content: flex-start;

    .titem {
      width: 219px;
      height: 288px;
      border-radius: 10px 10px 10px 10px;
      margin-right: 26px;
      position: relative;
      cursor: pointer;
      z-index: 99;


      .timg1 {
        overflow: hidden;
        width: 219px;
        height: 288px;
        border-radius: 10px 10px 10px 10px;

        img {
          border-radius: 10px 10px 10px 10px;
          width: 100%;
          height: auto;
          // object-fit: none;
        }
      }


      .titem_bot {
        width: 100%;
        height: 70px;
        position: absolute;
        bottom: 0;
        background-image: url("~@/assets/img/partner/z.png");
        display: flex;
        align-items: flex-end;
        padding: 13px 18px;
        background-repeat: no-repeat;
        border-radius: 0px 0px 10px 10px;

        .titem_tea {
          max-width: 120px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: bold;
          font-size: 22px;
          color: #ffffff;
          text-align: end;
        }

        .titem_teaMor {
          border-radius: 14px 14px 14px 14px;
          border: 1px solid #ffffff;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          padding: 3px 15px;
          flex-shrink: 0;
          margin-left: 20px;
        }
      }

      .mouseIn {
        width: 300px;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 0px 10px 10px 0px;
        background-image: url("~@/assets/img/partner/99.png");
        background-size: 100% 100%;
        position: absolute;
        left: 214px;
        top: 0;
        padding: 24px 20px;
        z-index: 999;

        .mouseIn_tname {
          display: flex;

          .mouseIn_tnames {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 22px;
            color: #1C81F5;
            max-width: 220px;
          }

          .mouseIn_teaMor {
            padding: 3px 15px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #1C81F5;
            border-radius: 14px 14px 14px 14px;
            border: 1px solid #1C81F5;
            margin-left: 10px;
            flex-shrink: 0;
            padding-top: 4px;
          }
        }

        .line {
          width: 22px;
          height: 2px;
          background: #1C81F5;
          border-radius: 0px 0px 0px 0px;
          margin-top: 20px;
        }

        .tD {
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          margin-top: 10px;
        }
      }



      .mouseIn-enter-active,
      .mouseIn-leave-active {
        transition: opacity 0.3s;
        // transform: scale(1.1);
      }

      .mouseIn-enter,
      .mouseIn-leave-to {
        opacity: 0;
      }
    }

    .titem:last-child {
      margin-right: 0;
    }



    .titem:nth-child(4),
    .titem:nth-child(5) {
      .mouseIn {
        position: absolute;
        left: -300px;
        background-image: url("~@/assets/img/partner/98.png");
        background-size: 100% 100%;
        border-radius: 10px 0px 0px 10px;


      }
    }

    .titem:hover {
      border-radius: 10px 0 0 10px !important;
      transform: scale(1.1);
      z-index: 999;

      img {
        border-radius: 10px 0 0 10px !important;

      }
    }

    .titem:nth-child(4):hover,
    .titem:nth-child(5):hover {
      border-radius: 0 10px 10px 0 !important;

      img {
        border-radius: 0 10px 10px 0 !important;

      }
    }
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

.moreEllipsis {
  -webkit-line-clamp: 8 !important;
}
</style>
